import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore';
import curdReducer from './curdReducer';
import { reducer as formReducer } from 'redux-form'


export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  curdReducer:curdReducer,
  form: formReducer
})