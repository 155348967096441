import React from 'react';
import Loader from 'react-loader-spinner';

const CustomLoader = () => {
  return (
    <div className="pageoverlay">
		  <Loader type="TailSpin" color="#0091ea" height={80} width={80} />
	  </div>
  );
};

const styles = {
  spinnerStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export { CustomLoader };
