import LocalizedStrings from 'react-localization';

import en from '../../i18n/locales/en.json';
import es from '../../i18n/locales/es.json';

let strings = new LocalizedStrings({
	en: en,
	es: es
});
if(window.localStorage.language){
	strings.setLanguage(window.localStorage.language);
}else{
	strings.setLanguage('es');
}

export {strings};
