import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import {strings}  from './components/localizedstrings/localizedstrings';

import {CustomLoader} from './components/loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const loading = () => (<CustomLoader/>);

// Containers
const DefaultLayout = Loadable({
	loader: () => import('./containers/DefaultLayout'),
	loading
});

// Pages
const Login = Loadable({
	loader: () => import('./views/Pages/Login'),
	loading
});

const Register = Loadable({
	loader: () => import('./views/Pages/Register'),
	loading
});

const Page404 = Loadable({
	loader: () => import('./views/Pages/Page404'),
	loading
});

const Page500 = Loadable({
	loader: () => import('./views/Pages/Page500'),
	loading
});

class App extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<HashRouter>
				<Switch>
					<Route exact path="/login" name="Login Page" component={Login} />
					<Route exact path="/register" name="Register Page" component={Register} />
					<Route exact path="/404" name="Page 404" component={Page404} />
					<Route exact path="/500" name="Page 500" component={Page500} />
					<Route path="/" name="Home" component={DefaultLayout} />
				</Switch>
			</HashRouter>
		);
	}
}

export default App;
