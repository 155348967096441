import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage';

import {firebaseConfig} from '../config';
import { rootReducer } from './reducers';


firebase.initializeApp(firebaseConfig)
firebase.firestore() 
firebase.storage();


const store = createStore(rootReducer, compose(applyMiddleware(thunk)));   

export default store