import {
    ADD_RECORD,
    EDIT_RECORD,
    DELETE_RECORD,
    LIST_RECORD,
    UPDATE_RECORD,
    RESET_STATE
  } from '../actions/types';
  
  const INITIAL_STATE = {
    edit_data:{},
    list_devices:{},
    error: '',
    isloading: false,
    lastUpdated:''
  };
  
  export default function(state = {}, action) {
  
    switch (action.type) {
        case ADD_RECORD:
        if (state[action.name] == undefined) {
          state[action.name] = [];
        }
        return {
          ...state,
          isloading: false,
          error: '',
          [action.name]: [...state[action.name], action.payload]
        };
        case EDIT_RECORD:
          var newState = Object.assign({}, action.payload);
          return { ...state, isloading: false, edit_data:newState,lastUpdated: Date.now()};
        case LIST_RECORD:
            return { ...state, isloading: false, [action.name]: action.payload};
        case DELETE_RECORD:
            let data = state[action.name].filter(item => action.payload !== item.id)
            return { ...state, isloading: false,lastUpdated: Date.now(),[action.name]:data};
        case UPDATE_RECORD:
            let update =''
            if(state[action.name]){
              update=state[action.name].map((content, i) => content.key === action.payload.key ? {...action.payload}: content)
            }else{
              update = {...action.payload}
            }
            return { ...state, isloading: false,lastUpdated: Date.now(),[action.name]:update};
        case RESET_STATE:
            return { INITIAL_STATE };
          default:
        return state;  
    }
  }
  